import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chat-charts',
  templateUrl: './chat-charts.component.html',
  styleUrls: ['./chat-charts.component.scss']
})
export class ChatChartsComponent {
  @Input() childChat: any;
  @Input() isVisible: boolean = true;
  @Output() visibilityChange = new EventEmitter<boolean>();
 

  chat: any;
  showRanking: boolean = false;
  showTrend: boolean = false;
  showEvents: boolean = false;
  showBenchmark: boolean = false;
  showChartsContainer: boolean = true; // New property to control visibility

  
  rankingScoreType: string | null = 'sentiment';
  trendScoreType: string | null = 'sentiment';
  eventsScoreType: string | null = 'sentiment';
  benchmarkScoreType: string | null = 'sentiment';




  ngOnInit(): void {
    this.setValue();

  }

  setValue(){
    this.chat=this.childChat;
    this.recommendedCharts();
  }


  recommendedCharts(){
   if (this.chat && this.chat.message) {
    const recommended_charts = this.chat.message.recommended_charts;

    if (recommended_charts && Array.isArray(recommended_charts)) { // if conditions
      recommended_charts.forEach(chart => {

        if(!chart.score_type){
          chart.score_type = 'sentiment';
        }
        
        switch (chart.chart_type) {
          case 'Ranking':
            this.showRanking = true;
            this.rankingScoreType = chart.score_type;
            break;
          case 'Trend':
            this.showTrend = true;
            this.trendScoreType = chart.score_type;
            break;
          case 'Events':
            this.showEvents = true;
            this.eventsScoreType = chart.score_type;
            break;
          case 'Benchmark':
            this.showBenchmark = true;
            this.benchmarkScoreType = chart.score_type;
            break;
          default:
            break;
        }
      });

  } else {
    console.error('this.chat or this.chat.message is undefined');
  }
}
  }


  closeCharts() {
    this.visibilityChange.emit(false);
  }

  openCharts() {
    this.visibilityChange.emit(true);

  }
}

