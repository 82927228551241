import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AIMessage,
  SourceDoc,
  FactWithSource,
} from "../interfaces/chart-details.interface";

@Component({
  selector: "app-chat-message-facts",
  styleUrls: ["./chat-message-facts.component.scss"],
  templateUrl: "./chat-message-facts.component.html",
})
export class MessageFactsComponent implements OnInit {
  @Input() aiMessage: AIMessage;
  @Output() articleClicked = new EventEmitter<{
    articleId: string;
    publishTime: string;
  }>();
  joinedMessages: string;
  factsWithSources: FactWithSource[] = [];

  constructor() {}

  ngOnInit(): void {
    if (!this.aiMessage.facts || this.aiMessage.facts.length === 0) {
      this.joinedMessages = this.processMessages();
    } else {
      this.factsWithSources = this.processFactsWithSources();
    }
  }
  formatFact(fact) {
    let formattedFact;

    if (!fact) return "";

    formattedFact = fact.trim()?.replace(/\n\n/g, "<br><br>")?.replace(/\n/g, "<br>");

    return formattedFact;
  }
  processMessages(): string {
    const message = this.aiMessage.messages
      .map((msg: string) => msg.trim())
      .join(" ")
      .replace(/\n\n/g, "<br><br>")
      .replace(/\n/g, "<br>");
    return message;
  }
  processFactsWithSources(): any[] {
    const factsWithSource = this.aiMessage?.facts?.map((fact) => {
      return {
        fact: fact.fact,
        source_docs: fact.source_doc_ids.map((sourceDocId: string) => {
          const sourceDoc = this.aiMessage.source_documents_events?.find(
            (doc: SourceDoc) => doc.id === sourceDocId
          );
          return {
            id: sourceDocId,
            title: sourceDoc?.title,
            publishTime: sourceDoc?.publish_time,
            provider: sourceDoc?.provider,
            eventNames: sourceDoc?.eventNames,
            citation: sourceDoc?.citation,
          };
        }),
      };
    });

    return factsWithSource;
  }

  onCitationClick(articleId: string, publishTime: string): void {
    this.articleClicked.emit({ articleId, publishTime });
  }
}
