// Angular
import { BrowserModule } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { NgModule, DoBootstrap, ApplicationRef, enableProdMode, Injectable, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

// Sentry
import * as Sentry from '@sentry/browser';
import { ErrorHandler } from '@angular/core';

// Guard
import { AuthGuard } from './guard/auth.guard';
import { ExpireGuard } from './guard/expire.guard';
import { AdminGuard } from './guard/admin.guard';
import { ReviewGuard } from './guard/review.guard';
import { MobileGuard } from './guard/mobile.guard';
import { NewsGuard } from './guard/news.guard';
import { TrendGuard } from './guard/trend.guard';
import { FolioGuard } from './guard/folio.guard';
import { QueryGuard } from './guard/query.guard';
import { RedirectGuard } from './guard/redirect.guard';
import { LoginRedirectGuard } from './guard/login-redirect.guard';
import { FiltersGuard } from './guard/filters.guard';
import { HomeGuard } from './guard/home.guard';
import { ScannerGuard } from './guard/scanner.guard';
import { ChatGuard } from './guard/chat.guard';

// Service
import { AuthService } from './service/auth.service';
import { ConfigService } from './service/config.service';
import { YukkApi } from './service/yukkapi.service';
import { RoutingService } from './service/routing.service';
import { NewsletterService } from './service/newsletter.service';

// Module
import { MaterialModule } from './module/material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgHttpCachingModule, NgHttpCachingConfig, NgHttpCachingStrategy } from 'ng-http-caching';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as hash from 'object-hash';
const hashOptions = {
    algorithm: 'md5',
    encoding: 'hex'
};
// caching configuration
const ngHttpCachingConfig: NgHttpCachingConfig = {
    // cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
    lifetime: 1000 * 60 * 60,
    allowedMethod: ['GET', 'POST'],
    getKey: (req: HttpRequest<any>): string | undefined => {
        return req.method + '@' + req.urlWithParams + '@' + hash(req.params, hashOptions) + '@' + hash(req.body, hashOptions);
    },
    isCacheable: (req: HttpRequest<any>): boolean | undefined => {
        // if ( (req.urlWithParams.indexOf('customer') !== -1) || (req.urlWithParams.indexOf('newsletter') !== -1) || (req.urlWithParams.indexOf('sentry') !== -1) || ((req.urlWithParams.indexOf('/news') !== -1) && !(req.urlWithParams.indexOf('event_ids') !== -1)) ) {
        //     return false;
        // }
        if ( (req.urlWithParams.indexOf('customer') !== -1) || (req.urlWithParams.indexOf('alerting') !== -1) || (req.urlWithParams.indexOf('reporting') !== -1) || (req.urlWithParams.indexOf('newsletter') !== -1) || (req.urlWithParams.indexOf('sentry') !== -1) || ((req.urlWithParams.indexOf('/news') !== -1) && !(req.urlWithParams.indexOf('/news_count_itemized') !== -1) && !(req.urlWithParams.indexOf('/news_item') !== -1) && !(req.urlWithParams.indexOf('event_ids') !== -1)) ) {
            return false;
        }
        // if ( (req.urlWithParams.indexOf('/stories') !== -1) || (req.urlWithParams.indexOf('/sentiment') !== -1) || (req.urlWithParams.indexOf('/score_ts') !== -1) || (req.urlWithParams.indexOf('/volume_ts') !== -1) || (req.urlWithParams.indexOf('/quotient_ts') !== -1) || (req.urlWithParams.indexOf('/ml_score_ts') !== -1) || (req.urlWithParams.indexOf('/events') !== -1) ) {
        //     return true;
        // }
        // by returning "undefined" normal "ng-http-caching" workflow is applied
        return undefined;
    }
};

// Pipe
import { HighlightPipe } from './pipe/highlight.pipe';
import { FragmentPipe } from './pipe/fragment.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { ParagraphPipe } from './pipe/paragraph.pipe';
import { MatchgroupPipe } from './pipe/matchgroup.pipe';
import { NicetxtPipe } from './pipe/nicetxt.pipe';
import { MatchgraphPipe } from './pipe/matchgraph.pipe';
import { BetaPipe } from './pipe/isbeta.pipe';
import { TrendPipe } from './pipe/trend.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { ColorPipe } from './pipe/color.pipe';
import { ColorVolumePipe } from './pipe/colorVolume.pipe';
import { ColorTrendingPipe } from './pipe/colorTrending.pipe';
import { ColorTrendingReversedPipe } from './pipe/colorTrendingReversed.pipe';
import { ColorRatioPipe } from './pipe/colorRatio.pipe';
import { ColorScorePipe } from './pipe/color-score.pipe';
import { ColorScore2Pipe } from './pipe/color-score2.pipe';
import { SizePipe } from './pipe/size.pipe';
import { OrderByTypePipe } from './pipe/orderbytype.pipe';
import { ThousandSuffixesPipe } from './pipe/thousand.pipe';
import { SafeHtmlPipe } from './pipe/safehtml.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { SearchPipe } from './pipe/search.pipe';

// Highchart
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as highstock from 'highcharts/modules/stock.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as offline from 'highcharts/modules/offline-exporting.src';
import * as exportdata from 'highcharts/modules/export-data.src';
import * as annotations from 'highcharts/modules/annotations.src';
import * as map from 'highcharts/modules/map.src';
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Admin
import { LoginComponent } from './admin/user/login/login.component';
import { RecordComponent } from './admin/user/record/record.component';
import { PasswordComponent } from './admin/user/password/password.component';
import { MessageComponent } from './admin/user/message/message.component';
import { ReportComponent } from './admin/report/report.component';
import { ReportListComponent } from './admin/report/report-list/report-list.component';
import { ReportViewComponent } from './admin/report/report-view/report-view.component';
import { AdminComponent } from './admin/admin.component';
import { QueryComponent } from './admin/query/query.component';
import { ReportExportComponent } from './admin/report/report-export/report-export.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { AdminAnnotationComponent } from './admin/annotation/annotation.component';

// Cockpit
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import { NewsFeedComponent } from './cockpit/main-news/news-newsfeed/news-feed/news-feed.component';
import { MenuFiltersComponent } from './cockpit/main-menu/menu-filters/menu-filters.component';
import { TrendSecsignalComponent } from './cockpit/main-trend/trend-secsignal/trend-secsignal.component';
import { TrendIndicatorComponent } from './cockpit/main-trend/trend-indicator/trend-indicator.component';
import { NetworkComponent } from './cockpit/main-news/news-network/network.component';
import { MainNewsfeedComponent } from './cockpit/main-news/news-newsfeed/news-newsfeed.component';
import { NewsViewComponent } from './cockpit/main-news/news-newsfeed/news-view/news-view.component';
import { PortfolioViewComponent } from './cockpit/main-news/news-portfolio/portfolio-view/portfolio-view.component';
import { PortfolioNameComponent } from './cockpit/main-news/news-portfolio/portfolio-name/portfolio-name.component';
import { PortfolioIndexComponent } from './cockpit/main-news/news-portfolio/portfolio-index/portfolio-index.component';
import { MainNewsComponent } from './cockpit/main-news/main-news.component';
import { DatepicherComponent } from './cockpit/main-menu/menu-filters/datepicher/datepicher.component';
import { MenuSearchComponent } from './cockpit/main-menu/menu-search/menu-search.component';
import { MenuFeedsComponent } from './cockpit/main-menu/menu-feeds/menu-feeds.component';
import { TrendMultichartComponent } from './cockpit/main-trend/trend-multichart/trend-multichart.component';
import { MainTrendComponent } from './cockpit/main-trend/main-trend.component';
import { NewsReportComponent } from './cockpit/main-news/news-newsfeed/news-report/news-report.component';
import { TagcloudComponent } from './cockpit/main-news/news-tagcloud/news-tagcloud.component';
import { NewsCountComponent } from './cockpit/main-news/news-newsfeed/news-count/news-count.component';
import { BsideComponent } from './cockpit/main-menu/menu-bside/menu-bside.component';
import { MobileComponent } from './project/mobile/mobile.component';
import { MenuAboveComponent } from './cockpit/main-menu/menu-above/menu-above.component';
import { MenuAsideComponent } from './cockpit/main-menu/menu-aside/menu-aside.component';
import { MainStepsComponent } from './cockpit/main-steps/main-steps.component';
import { StepsWelcomeComponent } from './cockpit/main-steps/steps-welcome/steps-welcome.component';
import { NewsPortfolioComponent } from './cockpit/main-news/news-portfolio/news-portfolio.component';
import { SignalInfoComponent } from './cockpit/main-trend/trend-secsignal/signal-info/signal-info.component';
import { MenuAccountComponent } from './cockpit/main-menu/menu-account/menu-account.component';
import { AnnotationComponent } from './cockpit/main-news/news-annotation/news-annotation.component';
import { NewsRadarComponent } from './cockpit/main-news/news-radar/news-radar.component';
import { NewsBoardComponent } from './cockpit/main-news/news-board/news-board.component';
import { BoardEntityComponent } from './cockpit/main-news/news-board/board-entity/board-entity.component';
import { NewsChartComponent } from './cockpit/main-news/news-chart/news-chart.component';
import { ChartLegendComponent } from './cockpit/main-news/news-chart/chart-legend/chart-legend.component';
import { ChartFolioComponent } from './cockpit/main-news/news-chart/chart-folio/chart-folio.component';
import { NewsTreemapComponent } from './cockpit/main-news/news-treemap/news-treemap.component';
import { NewsTrendingComponent } from './cockpit/main-news/news-trending/news-trending.component';
import { TrendingColumnComponent } from './cockpit/main-news/news-trending/trending-column/trending-column.component';
import { NewsTitleComponent } from './cockpit/main-news/news-title/news-title.component';
import { QueryDialogComponent } from './cockpit/main-news/news-query/query-dialog/query-dialog.component';
import { QueryGroupComponent } from './cockpit/main-news/news-query/query-dialog/query-group/query-group.component';
import { QuerySearchComponent } from './cockpit/main-news/news-query/query-dialog/query-search/query-search.component';
import { Query1GroupComponent } from './cockpit/main-news/news-query/query-dialog/query1-group/query1-group.component';
import { Query1SearchComponent } from './cockpit/main-news/news-query/query-dialog/query1-search/query1-search.component';
import { WidgetComponent } from './project/widget/widget.component';
import { WidgetIframeComponent } from './project/widget/widget-iframe/widget-iframe.component';
import { WidgetGalleryComponent } from './project/widget/widget-gallery/widget-gallery.component';
import { TrustUrlPipe } from './pipe/trust-url.pipe';
import { NegativePipe } from './pipe/negative.pipe';
import { InternetComponent } from './admin/internet/internet.component';
import { NewsChartmapComponent } from './cockpit/main-news/news-chartmap/news-chartmap.component';
import { Query2GroupComponent } from './cockpit/main-news/news-query/query-dialog/query2-group/query2-group.component';
import { Query2SearchComponent } from './cockpit/main-news/news-query/query-dialog/query2-search/query2-search.component';
import { NewsletterComponent } from './project/newsletter/newsletter.component';
import { NewsletterFormComponent } from './project/newsletter/newsletter-form/newsletter-form.component';
import { NewsletterPageComponent } from './project/newsletter/newsletter-page/newsletter-page.component';
import { PortfolioImportComponent } from './cockpit/main-news/news-portfolio/portfolio-import/portfolio-import.component';
import { EntityaskComponent } from './admin/entityask/entityask.component';
import { ChartmapCompanyComponent } from './cockpit/main-news/news-chartmap/chartmap-company/chartmap-company.component';
import { ColorTrendPipe } from './pipe/color-trend.pipe';
import { QueryFolioComponent } from './cockpit/main-news/news-query/query-folio/query-folio.component';
import { QueryChartComponent } from './cockpit/main-news/news-query/query-chart/query-chart.component';
import { QueryEditorComponent } from './cockpit/main-news/news-query/query-editor/query-editor.component';
import { QueryEditorGroupComponent } from './cockpit/main-news/news-query/query-editor/query-editor-group/query-editor-group.component';
import { QueryEditorSearchComponent } from './cockpit/main-news/news-query/query-editor/query-editor-search/query-editor-search.component';
import { NewsTagsComponent } from './cockpit/main-news/news-newsfeed/news-tags/news-tags.component';
import { NewsOutletComponent } from './cockpit/main-news/news-outlet/news-outlet.component';
import { QueryNameComponent } from './cockpit/main-news/news-query/query-name/query-name.component';
import { NewstypePipe } from './pipe/newstype.pipe';
import { NewsStoriesComponent } from './cockpit/main-news/news-stories/news-stories.component';
import { StoriesVisualComponent } from './cockpit/main-news/news-stories/stories-visual/stories-visual.component';
import { BoardEventComponent } from './cockpit/main-news/news-board/board-event/board-event.component';
import { NewsAuditComponent } from './cockpit/main-news/news-newsfeed/news-audit/news-audit.component';
import { ChartmapInfoComponent } from './cockpit/main-news/news-chartmap/chartmap-info/chartmap-info.component';
import { MenuOptionsComponent } from './cockpit/main-menu/menu-options/menu-options.component';
import { QueryAlertComponent } from './cockpit/main-news/news-query/query-alert/query-alert.component';
import { QueryImportComponent } from './cockpit/main-news/news-query/query-import/query-import.component';
import { NewsTableComponent } from './cockpit/main-news/news-table/news-table.component';
import { NewsParticipantsComponent } from './cockpit/main-news/news-participants/news-participants.component';
import { NewsletterSendComponent } from './project/newsletter/newsletter-send/newsletter-send.component';
import { NewsletterArticlesComponent } from './project/newsletter/newsletter-articles/newsletter-articles.component';
import { ScorelabSetupComponent } from './project/settings/scorelab-setup/scorelab-setup.component';
import { ScannerComponent } from './cockpit/scanner/scanner.component';
import { ScannerMainComponent } from './cockpit/scanner/scanner-main/scanner-main.component';
import { ScannerInsightsComponent } from './cockpit/scanner/scanner-insights/scanner-insights.component';
import { ScannerNewsComponent } from './cockpit/scanner/scanner-news/scanner-news.component';
import { ScannerItemsComponent } from './cockpit/scanner/scanner-items/scanner-items.component';
import { AlertsSetupComponent } from './project/settings/alerts-setup/alerts-setup.component';
import { NewsReportboxComponent } from './cockpit/main-news/news-newsfeed/news-reportbox/news-reportbox.component';
import { HelpComponent } from './cockpit/help/help.component';
import { HelpViewerComponent } from './cockpit/help/help-viewer/help-viewer.component';
import { PortfolioShareComponent } from './cockpit/main-news/news-portfolio/portfolio-share/portfolio-share.component';
import { RecommendationsComponent } from './project/recommendations/recommendations.component';
import { StatsComponent } from './project/stats/stats.component';
import { FlagsSetupComponent } from './project/settings/flags-setup/flags-setup.component';
import { MicroChartComponent } from './micro-components/charts/micro-chart/micro-chart.component';
import { TrendBarComponent } from './micro-components/charts/trend-bar/trend-bar.component';
import { DialogTableComponent } from './micro-components/dialog-table/dialog-table.component';
import { DialogReportComponent } from './micro-components/dialog-report/dialog-report.component';
import { NewsletterPreviewComponent } from './project/newsletter/newsletter-preview/newsletter-preview.component';
import { ChatComponent } from './project/llm/chat/chat.component';
import { DetectVisibilityDirective } from './detect-visibility.directive';
import { ChatNavigationComponent } from './project/llm/chat-navigation/chat-navigation.component';
import { EventSortingToggleComponent } from './cockpit/main-news/news-chartmap/chartmap-company/components/event-sorting-toggle.component/event-sorting-toggle.component';
import { RelatedQuestionsComponent } from './project/llm/chat-related-questions/chat-related-questos.component';
import { ArticleCardComponent } from './project/llm/chat-article-cards/chat-article-cards.component';
import { MessageFactsComponent } from './project/llm/chat-message-facts/chat-message-facts.component';
import { EventAnalyticsTemporalityComponent } from './cockpit/main-news/news-chartmap/chartmap-company/components/event-analytics-temporality.component/event-analytics-temporality.component';
import { ChatChartsComponent } from './project/llm/chat-charts/chat-charts.component';
import { ChatChartsRankingComponent } from './project/llm/chat-charts/chat-charts-ranking/chat-charts-ranking.component';
import { ChatChartsTrendComponent } from './project/llm/chat-charts/chat-charts-trend/chat-charts-trend.component';
import { ChatChartsEventsComponent } from './project/llm/chat-charts/chat-charts-events/chat-charts-events.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CockpitComponent,
        NewsFeedComponent,
        TrendSecsignalComponent,
        TrendIndicatorComponent,
        MenuFiltersComponent,
        NetworkComponent,
        MainNewsfeedComponent,
        PortfolioViewComponent,
        PortfolioNameComponent,
        PortfolioIndexComponent,
        MainNewsComponent,
        HighlightPipe,
        FragmentPipe,
        DatepicherComponent,
        MenuFeedsComponent,
        MenuSearchComponent,
        MenuFeedsComponent,
        TrendMultichartComponent,
        MainTrendComponent,
        RecordComponent,
        PasswordComponent,
        MessageComponent,
        NewsReportComponent,
        ReportComponent,
        ReportListComponent,
        ReportViewComponent,
        TagcloudComponent,
        NewsCountComponent,
        BsideComponent,
        AdminComponent,
        QueryComponent,
        MobileComponent,
        MenuAboveComponent,
        NewsViewComponent,
        ParagraphPipe,
        MenuAsideComponent,
        MainStepsComponent,
        StepsWelcomeComponent,
        NewsPortfolioComponent,
        ReportExportComponent,
        SignalInfoComponent,
        MatchgraphPipe,
        BetaPipe,
        MenuAccountComponent,
        MatchgroupPipe,
        NicetxtPipe,
        TrendPipe,
        SafePipe,
        SearchPipe,
        ColorPipe,
        ColorVolumePipe,
        ColorTrendingPipe,
        ColorTrendingReversedPipe,
        ColorRatioPipe,
        ColorScorePipe,
        ColorScore2Pipe,
        SizePipe,
        OrderByTypePipe,
        ThousandSuffixesPipe,
        AnnotationComponent,
        NewsRadarComponent,
        NewsBoardComponent,
        BoardEntityComponent,
        NewsChartComponent,
        ChartLegendComponent,
        ChartFolioComponent,
        NewsTreemapComponent,
        NewsTrendingComponent,
        TrendingColumnComponent,
        NewsTitleComponent,
        QueryGroupComponent,
        Query1GroupComponent,
        QuerySearchComponent,
        Query1SearchComponent,
        SafeHtmlPipe,
        FeedbackComponent,
        AdminAnnotationComponent,
        WidgetComponent,
        WidgetIframeComponent,
        WidgetGalleryComponent,
        TrustUrlPipe,
        NegativePipe,
        InternetComponent,
        NewsChartmapComponent,
        Query2GroupComponent,
        Query2SearchComponent,
        NewsletterComponent,
        NewsletterFormComponent,
        NewsletterPageComponent,
        PortfolioImportComponent,
        EntityaskComponent,
        ChartmapCompanyComponent,
        EventSortingToggleComponent,
        EventAnalyticsTemporalityComponent,
        ColorTrendPipe,
        QueryFolioComponent,
        QueryChartComponent,
        QueryEditorComponent,
        QueryEditorGroupComponent,
        QueryEditorSearchComponent,
        NewsTagsComponent,
        QueryDialogComponent,
        NewsOutletComponent,
        QueryNameComponent,
        NewstypePipe,
        NewsStoriesComponent,
        StoriesVisualComponent,
        BoardEventComponent,
        NewsAuditComponent,
        ChartmapInfoComponent,
        MenuOptionsComponent,
        QueryAlertComponent,
        QueryImportComponent,
        NewsTableComponent,
        NewsParticipantsComponent,
        NewsletterSendComponent,
        SanitizeHtmlPipe,
        NewsletterArticlesComponent,
        ScorelabSetupComponent,
        ScannerComponent,
        ScannerMainComponent,
        ScannerInsightsComponent,
        ScannerNewsComponent,
        ScannerItemsComponent,
        AlertsSetupComponent,
        NewsReportboxComponent,
        HelpComponent,
        HelpViewerComponent,
        PortfolioShareComponent,
        RecommendationsComponent,
        StatsComponent,
        FlagsSetupComponent,
        MicroChartComponent,
        TrendBarComponent,
        DialogTableComponent,
        DialogReportComponent,
        NewsletterPreviewComponent,
        ChatComponent,
        DetectVisibilityDirective,
        ChatNavigationComponent,
        RelatedQuestionsComponent,
        ArticleCardComponent,
        MessageFactsComponent,
        ChatChartsEventsComponent,
        ChatChartsRankingComponent,
        ChatChartsTrendComponent,
        ChatChartsComponent
        
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgPipesModule,
        InfiniteScrollModule,
        ChartModule,
        MomentModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        CKEditorModule,
        NgxChartsModule,
        MatSortModule,
        MatDialogModule,
        MaterialFileInputModule,
        NgxExtendedPdfViewerModule,
        NgHttpCachingModule.forRoot(ngHttpCachingConfig),
        AngularSvgIconModule.forRoot()
    ],
    providers: [
        AuthService,
        YukkApi,
        RoutingService,
        NewsletterService,
        SafePipe,
        SearchPipe,
        ColorPipe,
        ColorVolumePipe,
        ColorTrendingPipe,
        ColorTrendingReversedPipe,
        ColorRatioPipe,
        ColorScorePipe,
        ColorScore2Pipe,
        SizePipe,
        OrderByTypePipe,
        ThousandSuffixesPipe,
        AuthGuard,
        ExpireGuard,
        AdminGuard,
        ReviewGuard,
        MobileGuard,
        NewsGuard,
        TrendGuard,
        FolioGuard,
        QueryGuard,
        RedirectGuard,
        LoginRedirectGuard,
        FiltersGuard,
        HomeGuard,
        ScannerGuard,
        ChatGuard,
        {
            provide: HIGHCHARTS_MODULES, useFactory: () => [
                more,
                highstock,
                exporting,
                exportdata,
                offline,
                annotations,
                map,
            ]
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: {}
        },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => {
                return () => {
                    return configService.loadAppConfig();
                };
            },
            deps: [ConfigService],
            multi: true
        }
    ]
})
export class AppModule implements DoBootstrap {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('src/assets/mdi.svg'));
  }
  ngDoBootstrap(appRef: ApplicationRef) {
    // bootstrap AppComponent
    appRef.bootstrap(AppComponent);
    if (window.Cypress) {
      // save the application reference!
      console.log('App running Cypress');
      window.appRef = appRef;
    }
  }
}
