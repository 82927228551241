<div  [ngClass]="{'hidden': !isVisible}" class="charts__container" >
    <div class="charts__icon">
        <div style="display:flex; flex-wrap: wrap;">
        <div style="opacity: 0.8; margin-left: 10px; width: 100%;">News Analytics about {{chat.message.entityName}}</div>
        <div style="font-size: 12px; margin-top: 5px; opacity: 0.5; margin-left: 10px;">Last week</div>
    </div>
        <mat-icon class="charts__icon--close" id="chat-close-charts" (click)="closeCharts()">close</mat-icon>
    </div>

    <div>
    <app-chat-charts-trend *ngIf="showTrend"[childTrend]="chat.message.companyName" [childTrendScore]="trendScoreType"></app-chat-charts-trend>
    <app-chat-charts-events  *ngIf="showEvents" [childEvent]="chat.message.companyName" [childEventScore]="eventsScoreType"></app-chat-charts-events>
    <!-- <app-chat-charts-ranking *ngIf="showRanking" [childRanking]="chat.message.companyName"></app-chat-charts-ranking> -->
    <!-- <app-chat-charts-benchmark *ngIf="showEvents" [childBenchmark]="chat.message.companyName"></app-chat-charts-benchmark> -->
    </div>
</div>

