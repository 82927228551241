<div class="messages__textbox">
    <div class="message__messages" *ngIf="joinedMessages" [innerHTML]="joinedMessages"></div>
    <div *ngIf="factsWithSources && factsWithSources.length > 0">
        <div class="messages__facts" *ngFor="let item of factsWithSources">
            <span class="fact__text" [innerHTML]="formatFact(item.fact)"></span>
            <div class="citation__container" *ngFor="let docs of item.source_docs">
                <div id="chat-article-citation" *ngIf="docs.citation" class="citation__container citation-item" (click)="onCitationClick(docs.id, docs.publishTime)">
                    <span>{{docs.citation}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
