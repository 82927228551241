<div id="chat-article-card" class="article__card">
  <div *ngIf="eventNames?.length > 0 || citation" class="article__header">
    <span *ngIf="eventNames?.length > 0" class="article__header-event">{{ eventNames[0] }}</span>
    <div *ngIf="citation" class="article__header-citation">
      <span>{{citation}}</span>
    </div>
  </div>

  <div class="article__content">
    <div class="article__title--container">
      <div class="article__title">
        {{ title | slice: 0: 70 }}{{ title.length > 70 ? '...' : '' }}
      </div>
    </div>
    <div class="article__footer">
      <div class="article__published">
        <mat-icon class="icons">access_time</mat-icon>
        <span class="article__published">{{ publishTime | date: 'mediumDate' }}</span>
      </div>
      <div class="article__provider">
        <mat-icon class="icons">public</mat-icon>
        <span>{{ provider | slice: 0: 15 }}{{ provider.length > 15 ? '...' : '' }}</span>
      </div>
    </div>
  </div>
</div>