import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-article-card',
  templateUrl: './chat-article-cards.component.html',
  styleUrls: ['./chat-article-cards.component.scss']
})
export class ArticleCardComponent {
  @Input() title: string;
  @Input() publishTime: string;
  @Input() provider: string;
  @Input() eventNames: string[];
  @Input() citation: number;
}

